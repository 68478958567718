import { MediaQueries, MediaTypes } from '@frontend/api-media';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  Button,
  FieldProps,
  FileUpload,
  IconButton,
  Modal,
  Stepper,
  Text,
  useAlert,
  useModalControl,
} from '@frontend/design-system';
import { useBulkEmailEditorShallowStore } from '../../../hooks';
import { stepValueContainerStyles } from '../styles';
import { StepId, Step } from '../types';

type Props = {
  allAttachments: MediaTypes.MediaUploadResponse[];
  fieldProps: FieldProps<
    {
      attachments: {
        type: 'multiselect';
      };
    },
    'attachments'
  >;
  hide: boolean;
  missingAttachments: string[];
  previous: (stepId: StepId) => void;
  readOnly: boolean;
  selectedLocationId?: string;
};

export const useAttachmentStep = ({
  allAttachments,
  fieldProps,
  hide,
  missingAttachments,
  previous,
  readOnly,
  selectedLocationId,
}: Props): Step => {
  const { t } = useTranslation('bulk-messaging');
  const { modalProps, triggerProps } = useModalControl();
  const { setAttachments } = useBulkEmailEditorShallowStore('attachments', 'setAttachments');
  const alerts = useAlert();
  const attachments = fieldProps.value;

  const getAttachmentName = (attachmentId: string) => {
    const attachment = allAttachments.find((a) => a.ID === attachmentId);
    return attachment?.Name;
  };

  // TODO: this will be replaced by the SecureMedia service
  const { mutate: uploadMutation } = MediaQueries.useUploadMedia(
    {
      onSuccess: (media) => {
        alerts.success(t('Uploaded Successfully!'));
        fieldProps.onChange({ ...fieldProps, value: [...attachments, media.ID] });
        modalProps.onClose();
      },
      onError: () => {
        alerts.error(t('There was an error uploading your file. Please try again later.'));
      },
    },
    MediaTypes.MediaUploadTypes.Attachment,
    selectedLocationId
  );

  const AttachmentButtons =
    attachments.length > 0 ? (
      attachments.map((attachmentId) => (
        <IconButton
          key={attachmentId}
          label=''
          css={{
            width: 'fit-content',
            backgroundColor: theme.colors.neutral10,
            border: `2px solid ${
              missingAttachments.find((id) => id === attachmentId) ? theme.colors.critical50 : theme.colors.primary50
            }`,
          }}
          disabled={readOnly}
          onClick={() => {
            fieldProps.onChange({ ...fieldProps, value: attachments.filter((id) => id !== attachmentId) });
            setAttachments(attachments.filter((id) => id !== attachmentId));
          }}
          trackingId={`${BulkEmailPrefixes.Editor}-attachments-remove-btn`}
        >
          {!readOnly && <Icon name='x' />}
          <Text>{getAttachmentName(attachmentId)}</Text>
        </IconButton>
      ))
    ) : (
      <Text color='light'>{t('0 Attachments')}</Text>
    );

  const id: StepId = 'attachments';

  return {
    id,
    Component: (
      <>
        <Stepper.Title>{t('Upload & Attach Files')}</Stepper.Title>

        <Stepper.Content css={{ display: 'flex', flexDirection: 'column', rowGap: theme.spacing(2) }}>
          <Text size='medium'>{t('Attach a file to your email.')}</Text>{' '}
          {/* TODO: replace text once we will support more than one attachment: `t('Attach some files to your email.')` */}
          {AttachmentButtons}
          {/* TODO: first iteration will only support one attachment */}
          {!attachments.length && (
            <div>
              <Button
                {...triggerProps}
                css={{ marginTop: theme.spacing(3) }}
                trackingId={`${BulkEmailPrefixes.Editor}-attachments-upload-btn`}
              >
                {t('Upload')}
              </Button>
            </div>
          )}
        </Stepper.Content>

        <Stepper.ButtonBarAlternate>
          <Stepper.ButtonAlternate type='previous' position='secondary' onClick={() => previous(id)}>
            {t('Previous')}
          </Stepper.ButtonAlternate>
        </Stepper.ButtonBarAlternate>

        <Modal {...modalProps} minWidth={600}>
          <Modal.Header onClose={modalProps.onClose}>{t('Upload Files')}</Modal.Header>
          <Modal.Body css={{ marginTop: theme.spacing(2) }}>
            <FileUpload
              maxFileSize={10000000}
              helperText={
                <>
                  <Text css={{ marginTop: theme.spacing(1) }} weight='bold'>
                    {t('Upload Attachments')}
                  </Text>
                  <Text css={{ marginTop: theme.spacing(1) }}>{t('Drop PDF, JPG, PNG, or DOCX files here.')}</Text>
                  <Text>{t('Max file size: 10MB.')}</Text>
                </>
              }
              validateUpload={async (_file) => {
                // if there is no location selected, we don't want to upload the file all together
                if (!selectedLocationId) return t('Please select a location first.');
                return '';
              }}
              onFileUpload={async (files) => {
                const file = files[0];
                if (!file) return;
                // converts the file to a blob which is needed for the upload media mutation
                const newFile = new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
                uploadMutation({ data: newFile, filename: file.name, type: MediaTypes.MediaUploadTypes.Attachment });
              }}
              trackingId={`${BulkEmailPrefixes.Editor}-attachments-modal`}
            />
          </Modal.Body>
        </Modal>
      </>
    ),
    collapsedValue: (
      <div css={{ ...stepValueContainerStyles, flexDirection: 'column' }}>
        <Text>{t('Upload & Attach Files')}</Text>
        {AttachmentButtons}
      </div>
    ),
    hide,
  };
};
