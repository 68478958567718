import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FieldChangeEvent, SwitchChipGroup } from '@frontend/design-system';

type DaysToSendProps = {
  disabled?: boolean;
  name: string;
  onChange: (e?: FieldChangeEvent) => void;
  trackingId?: string;
  value: string[];
};

const getToggledDay = (prevDays: string[], newDays: string[]): string | null => {
  if (newDays.length === prevDays.length + 1) {
    return newDays.find((day) => !prevDays.includes(day)) || null;
  }

  if (newDays.length === prevDays.length - 1) {
    return prevDays.find((day) => !newDays.includes(day)) || null;
  }

  return null;
};

export const DaysToSend = ({ disabled, name, onChange, trackingId, value }: DaysToSendProps) => {
  const { t } = useTranslation('bulk-messaging');
  const options = [
    { name: 'sunday', label: t('Sun') },
    { name: 'monday', label: t('Mon') },
    { name: 'tuesday', label: t('Tues') },
    { name: 'wednesday', label: t('Wed') },
    { name: 'thursday', label: t('Thurs') },
    { name: 'friday', label: t('Fri') },
    { name: 'saturday', label: t('Sat') },
  ];

  const setValue: Dispatch<SetStateAction<string[]>> = (action) => {
    const newDays = typeof action === 'function' ? action(value) : action;
    const toggledDay = getToggledDay(value, newDays);

    if (!toggledDay) return;

    const changeEvent: FieldChangeEvent = {
      name,
      value: toggledDay,
    };

    onChange(changeEvent);
  };

  return (
    <SwitchChipGroup
      css={{
        pointerEvents: 'auto',
        marginTop: theme.spacing(2),
        '> .chip': {
          height: theme.spacing(3),
        },
      }}
      disabled={disabled}
      value={value}
      setValue={setValue}
    >
      {options.map(({ name, label }) => (
        <SwitchChipGroup.Option
          key={name}
          value={name}
          trackingId={trackingId ? `${trackingId}-${name}-checkbox` : undefined}
        >
          {label}
        </SwitchChipGroup.Option>
      ))}
    </SwitchChipGroup>
  );
};
