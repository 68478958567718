import { useEffect, useState } from 'react';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { DeviceSize } from '@frontend/device-size-field';
import { EmailPreviewModal, usePreviewModal } from '@frontend/email-preview-modal';
import { EmailThumbnail } from '@frontend/email-thumbnail';
import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, Stepper, Text } from '@frontend/design-system';
import { useBulkEmailEditorShallowStore } from '../../../hooks';
import { stepValueContainerStyles } from '../styles';
import { Step, StepId } from '../types';

type UseEmailContentStepParams = {
  contentError: string;
  next: (stepId: StepId) => void;
  openComposer: () => void;
  previous: (stepId: StepId) => void;
};

export const useEmailContentStep = ({
  contentError,
  next,
  openComposer,
  previous,
}: UseEmailContentStepParams): Step => {
  const { t } = useTranslation('bulk-messaging');
  const store = useBulkEmailEditorShallowStore(
    'setSubject',
    'setTemplateHtml',
    'setTemplateJson',
    'templateHtml',
    'templateJson',
    'thumbnailImage',
    'thumbnailUrl'
  );

  const [previewHtml, setPreviewHtml] = useState<string>();
  const { deviceSizeProps, generatePreview, previewModalProps, transformedText } = usePreviewModal(
    previewHtml,
    TemplateType_Slug.BULK_MESSAGE
  );

  const handlePreviewModalOpen = () => setPreviewHtml(store.templateHtml);

  useEffect(() => {
    if (transformedText) {
      generatePreview();
    }
  }, [previewHtml, transformedText]);

  const deleteEmail = () => {
    store.setSubject('');
    store.setTemplateHtml('');
    store.setTemplateJson('');
  };

  useEffect(() => {
    if (!previewModalProps.show) {
      setPreviewHtml(undefined);
    }
  }, [previewModalProps.show]);

  const id: StepId = 'content';

  return {
    id,
    Component: (
      <>
        <Stepper.Title>{t('Email Content')}</Stepper.Title>

        <Stepper.Content>
          <Text size='medium' css={{ marginBottom: theme.spacing(2) }}>
            {t('Build or use a template to construct your email.')}
          </Text>
          {!!store.templateJson ? (
            <>
              <EmailThumbnail
                src={store.thumbnailImage ? store.thumbnailImage : store.thumbnailUrl}
                onClick={handlePreviewModalOpen}
                css={{ marginBottom: theme.spacing(2) }}
                width={200}
                height={200}
                alt={t('Edit Email')}
              />
              {contentError && (
                <Text size='small' color='error' css={{ marginBottom: theme.spacing(1) }}>
                  {contentError}
                </Text>
              )}
              <div css={{ display: 'flex', columnGap: theme.spacing(1), marginTop: theme.spacing(3) }}>
                <Button
                  variant='secondary'
                  onClick={openComposer}
                  trackingId={`${BulkEmailPrefixes.Editor}-edit-email-btn`}
                >
                  {t('Edit Email')}
                </Button>
                <Button
                  variant='tertiary'
                  onClick={deleteEmail}
                  trackingId={`${BulkEmailPrefixes.Editor}-delete-email-btn`}
                  destructive
                >
                  {t('Delete')}
                </Button>
              </div>
            </>
          ) : (
            <>
              {contentError && (
                <Text size='small' color='error' css={{ marginBottom: theme.spacing(1) }}>
                  {contentError}
                </Text>
              )}
              <Button
                onClick={openComposer}
                css={{ marginTop: theme.spacing(2) }}
                trackingId={`${BulkEmailPrefixes.Editor}-compose-email-btn`}
              >
                {t('Compose Email')}
              </Button>
            </>
          )}
        </Stepper.Content>

        <Stepper.ButtonBarAlternate>
          <Stepper.ButtonAlternate onClick={() => previous(id)} position='secondary' type='none'>
            {t('Previous')}
          </Stepper.ButtonAlternate>
          <Stepper.ButtonAlternate onClick={() => next(id)} position='primary' type='none'>
            {t('Next')}
          </Stepper.ButtonAlternate>
        </Stepper.ButtonBarAlternate>
        {previewModalProps.show && (
          <EmailPreviewModal
            deviceSizeProps={{ ...deviceSizeProps, value: deviceSizeProps?.value as DeviceSize }}
            modalProps={previewModalProps}
            previewHtml={transformedText}
            setPreviewHtml={setPreviewHtml}
          />
        )}
      </>
    ),
    collapsedValue: (
      <div css={{ ...stepValueContainerStyles, flexDirection: 'column' }}>
        <Text>{t('Email Content')}</Text>
        {store.thumbnailImage || store.thumbnailUrl ? (
          <EmailThumbnail
            src={store.thumbnailImage ? store.thumbnailImage : store.thumbnailUrl}
            width={200}
            height={200}
            alt={t('Edit Email')}
          />
        ) : (
          <EmailThumbnail />
        )}
      </div>
    ),
    readValue: (
      <div css={{ ...stepValueContainerStyles, flexDirection: 'column' }}>
        <Text>{t('Email Content')}</Text>
        <EmailThumbnail
          src={store.thumbnailImage ? store.thumbnailImage : store.thumbnailUrl}
          onClick={handlePreviewModalOpen}
          width={200}
          height={200}
          alt={t('Edit Email')}
        />
        <div css={{ display: 'flex', columnGap: theme.spacing(1) }}>
          <Button variant='secondary' onClick={handlePreviewModalOpen}>
            {t('View')}
          </Button>
        </div>
        {previewModalProps.show && (
          <EmailPreviewModal
            deviceSizeProps={{ ...deviceSizeProps, value: deviceSizeProps?.value as DeviceSize }}
            modalProps={previewModalProps}
            previewHtml={transformedText}
            setPreviewHtml={setPreviewHtml}
          />
        )}
      </div>
    ),
  };
};
