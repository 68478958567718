import { ProcessingStrategy_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/auto-rules/v1/models.pb';
import { DataType } from '@weave/schema-gen-ts/dist/schemas/syncapp/integrations-service/capability.pb';
import { IntegrationsService } from '@weave/schema-gen-ts/dist/schemas/syncapp/integrations-service/integrations_service.pb.js';
import { AutoRulesQueries } from '@frontend/api-auto-rules';
import { type PersonTypes, PersonTypes as PersonTypesEnums } from '@frontend/api-person';
import { AutoMessageDetailContactPanel } from '@frontend/auto-message-detail-contact-panel';
import { useDeleteContactAction } from '@frontend/contact-actions-delete-contact';
import { useTranslationTokens } from '@frontend/i18n-tokens';
import { Icon } from '@frontend/icons';
import { InsuranceDetails } from '@frontend/insurance-details';
import { useLastUsedVerticalShallowStore, useLocalizedQuery } from '@frontend/location-helpers';
import { bindHTTP } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { TextButton } from '@frontend/design-system';
import { ContactDetails } from '../contact-details';
import { PetOverview, AppointmentInformation, ContactBalance, CustomerInformation, Household } from './sections';

export const SchemaAddressService = bindHTTP(IntegrationsService);
export type CustomerDetailsProps = {
  data?: PersonTypes.Person;
  locationId?: string;
};

export const CustomerDetails = ({ data, locationId }: CustomerDetailsProps) => {
  const sourceId = data?.SourceID;
  const { data: integrationsQueryData } = useLocalizedQuery({
    queryKey: ['integrations', sourceId],
    queryFn: () => SchemaAddressService.GetDataSourceBySourceID({ sourceId }),
    enabled: !!sourceId,
  });
  const hasAgingIntegration = integrationsQueryData?.dataSource?.integration?.capabilities?.find(
    (capability) => capability.dataType === DataType.AGING
  );
  const isCustomContact = data?.SourceType === PersonTypesEnums.PersonSourceType['CustomContact'];
  const isCsv = data?.SourceType === PersonTypesEnums.PersonSourceType['Csv'];
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');
  const isVet = lastUsedVertical === 'VET';
  const isDental = lastUsedVertical === 'DENTAL';
  const contactIsIntegrated = !isCustomContact && !isCsv;

  const { selectedLocationIds, selectedOrgId } = useAppScopeStore();
  const { data: messageTypes } = AutoRulesQueries.useListAutoMessageTypes({
    orgId: selectedOrgId,
    businessGroupIds: selectedLocationIds,
    skipMessageTypeValidation: true,
  });
  const showAutoMessageDetails =
    Object.values(messageTypes || {}).filter(
      (messageType) =>
        messageType.processingStrategy !== ProcessingStrategy_Enum.ON_SYNC &&
        messageType.processingStrategy !== ProcessingStrategy_Enum.INVALID
    ).length > 0;

  return (
    <>
      {isVet ? <PetOverview data={data} locationId={locationId} /> : <Household data={data} />}
      <ContactDetails>
        <CustomerInformation data={data} locationId={locationId} />
      </ContactDetails>
      {contactIsIntegrated && (
        <ContactDetails>
          <AppointmentInformation data={data} />
        </ContactDetails>
      )}
      {isDental && (
        <ContactDetails>
          <InsuranceDetails person={data} locationId={locationId || ''} />
        </ContactDetails>
      )}
      {contactIsIntegrated && (
        <>
          {hasAgingIntegration && (
            <ContactDetails>
              <ContactBalance data={data} />
            </ContactDetails>
          )}
          {showAutoMessageDetails && <AutoMessageDetailContactPanel person={data} />}
        </>
      )}
      {isCustomContact && <DeleteContact personId={data.PersonID} locationId={locationId || ''} />}
    </>
  );
};

const TrashIcon = () => <Icon name='trash' />;

const DeleteContact = ({ personId, locationId }: { personId: string; locationId: string }) => {
  const tokens = useTranslationTokens();
  const { triggerProps, DeleteConfirmationModal } = useDeleteContactAction({ personId, locationId });

  return (
    <>
      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <TextButton
          trackingId='contact-panel-delete-contact-btn'
          color='critical'
          icon={TrashIcon}
          css={{ color: theme.colors.status.critical }}
          {...triggerProps}
        >
          {tokens.deleteContact}
        </TextButton>
      </div>
      {DeleteConfirmationModal}
    </>
  );
};
