import { useEffect, useMemo } from 'react';
import { BulkMessagingMutations, BulkMessagingQueries, BulkMessagingTypes } from '@frontend/api-bulk-messaging';
import { AudienceSelectionTray } from '@frontend/audience-selection-form';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, SpinningLoader, Stepper, Text, useModalControl } from '@frontend/design-system';
import { useBulkEmailEditorShallowStore } from '../../../hooks';
import { overrideTextStyles, stepValueContainerStyles } from '../styles';
import { Step, StepId } from '../types';

type Props = {
  createHiddenDraft: () => void;
  locationIds: string[];
  next?: (stepId: StepId) => void;
  previous: (stepId: StepId) => void;
  handleCreateSegment: (audienceFilter?: BulkMessagingTypes.AudienceFilters, listId?: string) => void;
  isCreatingSegment: boolean;
};

export const useAudienceStep = ({
  createHiddenDraft,
  locationIds,
  next,
  previous,
  handleCreateSegment,
  isCreatingSegment,
}: Props): Step => {
  const { t } = useTranslation('bulk-messaging');
  const { selectedOrgId: orgId } = useAppScopeStore();
  const { audienceCount, campaignId, enableValidation, setAudienceCount } = useBulkEmailEditorShallowStore(
    'audienceCount',
    'campaignId',
    'enableValidation',
    'setAudienceCount'
  );
  const { data: audience } = BulkMessagingQueries.useGetAudience({ campaignId, orgId });
  const { data: segments = [] } = BulkMessagingQueries.useListSegments({ campaignId, orgId });

  useEffect(() => {
    if (audience) {
      setAudienceCount(audience.total);
    }
  }, [audience]);

  const duplicateCount = useMemo(() => {
    // TODO: waiting for the BE is done for getting this data
    return 0;
  }, [audience]);

  const hasSegments = segments.length > 0;
  const audienceError = enableValidation ? (audienceCount ? '' : t('An audience is required')) : '';
  const { mutate: deleteSegment } = BulkMessagingMutations.useDeleteSegment();

  const { openModal, modalProps, closeModal, triggerProps } = useModalControl();

  const handleOpen = () => {
    createHiddenDraft();
    openModal();
  };

  const id: StepId = 'audience';

  return {
    id,
    Component: (
      <div css={{ '.step-button-bar > div': { display: 'flex' } }}>
        <Stepper.Title>{t('Build Audience')}</Stepper.Title>

        <Stepper.Content>
          <Text size='medium'>{t('Target the right people by building your audience with filters.')}</Text>
          {hasSegments && (
            <div
              {...triggerProps}
              onClick={handleOpen}
              css={{
                marginTop: theme.spacing(3),
                padding: theme.spacing(2),
                display: 'flex',
                border: `solid 1px ${theme.colors.neutral20}`,
                borderRadius: theme.borderRadius.medium,
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                cursor: 'pointer',
              }}
              data-trackingid={`${BulkEmailPrefixes.Editor}-edit-audience-btn`}
            >
              <div>
                <Text size='medium' weight='bold' css={{ marginBottom: theme.spacing(0.5) }}>
                  {audienceCount === 1
                    ? t('1 Contact in Audience')
                    : t('{{contact}} Contacts in Audience', { contact: audienceCount })}
                </Text>
                <Text size='medium'>
                  {segments.length === 1
                    ? t('Including 1 Segment')
                    : t('Including {{segment}} Segment', { segment: segments.length })}
                </Text>
              </div>
              <Icon name='edit' />
            </div>
          )}
          {isCreatingSegment && (
            <Text size='medium' css={{ display: 'flex', alignItems: 'center', margin: theme.spacing(2, 0, 1, 0) }}>
              <SpinningLoader size='xs' css={{ marginRight: theme.spacing(0.5) }} />
              {t('Creating Segment...')}
            </Text>
          )}
          {audienceError && (
            <Text size='small' color='error' css={{ marginTop: theme.spacing(1) }}>
              {audienceError}
            </Text>
          )}
          {!hasSegments && (
            <Button
              {...triggerProps}
              onClick={handleOpen}
              css={{ marginTop: theme.spacing(2) }}
              trackingId={`${BulkEmailPrefixes.Editor}-create-audience-btn`}
            >
              {t('Create Audience')}
            </Button>
          )}
          {modalProps.show && (
            <AudienceSelectionTray
              accessibleLocationIds={locationIds} // should only be the selected location
              audienceCount={audienceCount}
              campaignId={campaignId}
              closeModal={closeModal}
              duplicateCount={duplicateCount}
              isCreatingSegment={isCreatingSegment}
              modalProps={modalProps}
              onCreateSegment={handleCreateSegment}
              setAudienceCount={setAudienceCount}
            />
          )}
        </Stepper.Content>

        <Stepper.ButtonBarAlternate>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: segments.length > 0 ? 'space-between' : 'flex-end',
              width: '100%',
            }}
          >
            {hasSegments && (
              <Stepper.ButtonAlternate
                css={{ color: theme.colors.critical50 }}
                onClick={() => {
                  segments.forEach(({ segmentId }) => deleteSegment({ campaignId, segmentId, orgId }));
                  setAudienceCount(undefined);
                }}
                trackingId={`${BulkEmailPrefixes.Editor}-delete-audience-btn`}
                position='tertiary'
                type='none'
              >
                {t('Delete Audience')}
              </Stepper.ButtonAlternate>
            )}
            <div css={{ display: 'flex', columnGap: theme.spacing(2) }}>
              <Stepper.ButtonAlternate
                onClick={() => previous(id)}
                position={next ? 'secondary' : 'primary'}
                type='previous'
              >
                {t('Previous')}
              </Stepper.ButtonAlternate>
              {next && (
                <Stepper.ButtonAlternate onClick={() => next(id)} position='primary' type='next'>
                  {t('Next')}
                </Stepper.ButtonAlternate>
              )}
            </div>
          </div>
        </Stepper.ButtonBarAlternate>
      </div>
    ),
    collapsedValue: (
      <div css={{ ...stepValueContainerStyles, flexDirection: 'column' }}>
        <Text>{t('Build Audience')}</Text>
        {hasSegments && (
          <Text css={{ ...overrideTextStyles, textTransform: 'initial' }}>
            {audienceCount === 1
              ? t('1 Contact in Audience')
              : t('{{contact}} Contacts in Audience', { contact: audienceCount })}
          </Text>
        )}
      </div>
    ),
  };
};
