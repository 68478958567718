import dayjs from 'dayjs';
import { formatTime } from '@frontend/date';
import { Divider } from '@frontend/divider';
import { Trans, useTranslation } from '@frontend/i18n';
import { titleCase } from '@frontend/string';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  DatePickerField,
  FieldProps,
  FormRow,
  NumberField,
  RadioCardField,
  Stepper,
  Text,
  TimeField,
  formatDate,
} from '@frontend/design-system';
import { DaysToSend } from '../days-to-send';
import { overrideTextStyles, stepValueContainerStyles } from '../styles';
import { SendType, Step, StepId } from '../types';

type Props = {
  fieldProps: {
    date: FieldProps<
      {
        date: {
          type: 'date';
        };
      },
      'date'
    >;
    time: FieldProps<
      {
        time: {
          type: 'time';
        };
      },
      'time'
    >;
    sendOptions: FieldProps<
      {
        sendOptions: {
          type: 'radio';
        };
      },
      'sendOptions'
    >;
    sendInterval: FieldProps<
      {
        sendInterval: {
          type: 'number';
        };
      },
      'sendInterval'
    >;
    daysToSend: FieldProps<
      {
        daysToSend: {
          type: 'checklist';
        };
      },
      'daysToSend'
    >;
  };
  next: (stepId: StepId) => void;
  previous: (stepId: StepId) => void;
  readOnly: boolean;
  sendIntervalError: string;
};

export const useWhenToSendStep = ({ fieldProps, next, previous, readOnly, sendIntervalError }: Props): Step => {
  const { t } = useTranslation('bulk-messaging');
  const {
    date: dateFieldProps,
    time: timeFieldProps,
    sendInterval: sendIntervalFieldProps,
    sendOptions: sendOptionsFieldProps,
    daysToSend: daysToSendFieldProps,
  } = fieldProps;

  const convertToReadableDays = (days?: string[]) => {
    if (!days) return '';
    const lastIndex = days.length - 1;
    if (days.length === 7) return t('per day');

    return days.reduce((acc, day, index) => {
      const formattedDay = titleCase(day);
      if (days.length === 1 || index === 0) return `${acc} ${formattedDay}`;
      if (index === lastIndex) return `${acc} and ${formattedDay}`;
      return `${acc}, ${formattedDay}`;
    }, 'on');
  };

  const id: StepId = 'when-to-send';

  return {
    id,
    Component: (
      <>
        <Stepper.Title>{t('Delivery Schedule')}</Stepper.Title>

        <Stepper.Content css={{ maxWidth: 474 }}>
          <p css={{ marginBottom: theme.spacing(2) }}>
            {t('Select the day and time you would like to launch this email campaign.')}
          </p>
          <div css={{ display: 'flex', alignItems: 'center', marginBottom: theme.spacing(2) }}>
            <DatePickerField
              {...dateFieldProps}
              label={t('Campaign Start Date')}
              disabled={readOnly}
              data-trackingid={`${BulkEmailPrefixes.Editor}-send-date-input`}
            />
            <span css={{ display: timeFieldProps.hidden ? 'none' : 'inline-block', margin: theme.spacing(0, 2) }}>
              {t('at about')}
            </span>
            <TimeField
              {...timeFieldProps}
              css={{ maxWidth: 130 }}
              label={t('Launch Time')}
              interval={30}
              disabled={readOnly}
              data-trackingid={`${BulkEmailPrefixes.Editor}-send-time-input`}
            />
          </div>
          {!fieldProps.sendOptions.hidden && (
            <p css={{ margin: theme.spacing(3, 0, 2, 0) }}>
              {t('Select the method of delivery that will best suit your email campaign.')}
            </p>
          )}
          <FormRow>
            <RadioCardField
              {...fieldProps.sendOptions}
              css={{
                '> div': {
                  display: fieldProps.sendOptions.hidden ? 'none' : 'flex',
                  flexDirection: 'column',
                },
              }}
              disabled={readOnly}
            >
              <RadioCardField.Option
                value={SendType.SendAll}
                trackingId={`${BulkEmailPrefixes.Editor}-send-all-radio`}
                title={t('Prompt Delivery')}
                description={
                  <div css={{ color: theme.colors.neutral50 }}>
                    {t(
                      'Emails begin sending at your scheduled launch time and typically complete within an hour. This method is great for quickly distributing information, but may overwhelm your ability to handle campaign replies.'
                    )}
                  </div>
                }
              />
              <RadioCardField.Option
                value={SendType.SendSpecific}
                trackingId={`${BulkEmailPrefixes.Editor}-send-daily-radio`}
                title={t('Gradual Delivery')}
                description={
                  <>
                    <p>
                      {t(
                        'Send up to a set number of emails per day to maintain a controlled pace. This method helps manage campaign replies more easily while ensuring a steady flow of information.'
                      )}
                    </p>
                    {sendOptionsFieldProps.value === SendType.SendSpecific && (
                      <>
                        <Divider />
                        <Text size='medium'>{t('Set the amount of emails that will go out per day')}</Text>
                        <Text size='medium'>
                          <Trans t={t}>
                            <div
                              css={{
                                display: 'inline-block',
                                pointerEvents: 'auto',
                                width: theme.spacing(9),
                                margin: theme.spacing(1, 1, 0, 0),
                              }}
                            >
                              <NumberField
                                {...sendIntervalFieldProps}
                                aria-invalid={sendIntervalFieldProps['aria-invalid'] || sendIntervalError !== ''}
                                required={
                                  sendIntervalFieldProps.required ||
                                  sendOptionsFieldProps.value === SendType.SendSpecific
                                }
                                disabled={readOnly || sendOptionsFieldProps.value !== SendType.SendSpecific}
                                error=''
                                label=''
                                css={[
                                  {
                                    padding: theme.spacing(0, 1),
                                    backgroundColor: theme.colors.white,
                                  },
                                  sendIntervalError !== '' && {
                                    borderColor: theme.colors.text.critical,
                                    '&:has(:is(input, textarea):focus)': {
                                      boxShadow: `0 0 0 1px ${theme.colors.text.critical}`,
                                    },
                                  },
                                  {
                                    '> input': {
                                      textAlign: 'center',
                                    },
                                  },
                                ]}
                                onKeyDown={(e) => {
                                  e.stopPropagation();
                                }}
                                data-trackingid={`${BulkEmailPrefixes.Editor}-send-interval-input`}
                              />
                            </div>{' '}
                            emails per day
                          </Trans>
                        </Text>
                        {sendIntervalError !== '' && (
                          <Text color='error' size='small' css={{ marginTop: theme.spacing(1) }}>
                            {sendIntervalError}
                          </Text>
                        )}
                        <Text size='medium' css={{ marginTop: theme.spacing(2) }}>
                          {t('Select days of the week campaign will send')}
                        </Text>
                        <DaysToSend
                          disabled={true}
                          name={daysToSendFieldProps.name}
                          onChange={daysToSendFieldProps.onChange}
                          trackingId={`${BulkEmailPrefixes.Editor}-days-to-send`}
                          value={daysToSendFieldProps.value}
                        />
                      </>
                    )}
                  </>
                }
              ></RadioCardField.Option>
            </RadioCardField>
          </FormRow>
        </Stepper.Content>

        <Stepper.ButtonBarAlternate>
          <Stepper.ButtonAlternate onClick={() => previous(id)} position='secondary' type='none'>
            {t('Previous')}
          </Stepper.ButtonAlternate>
          <Stepper.ButtonAlternate onClick={() => next(id)} position='primary' type='none'>
            {t('Next')}
          </Stepper.ButtonAlternate>
        </Stepper.ButtonBarAlternate>
      </>
    ),
    collapsedValue: (
      <div css={{ ...stepValueContainerStyles, flexDirection: 'column' }}>
        <Text>{t('Delivery Schedule')}</Text>
        {dateFieldProps.value && timeFieldProps.value && sendOptionsFieldProps.value && (
          <>
            <Text css={{ ...overrideTextStyles, textTransform: 'initial' }}>
              {t('{{day}} at {{time}}', {
                day: formatDate(dayjs(dateFieldProps.value), 'M/D/YY'),
                time: formatTime(timeFieldProps.value),
              })}
            </Text>
            <Text css={{ ...overrideTextStyles, textTransform: 'initial' }}>
              {sendOptionsFieldProps.value === SendType.SendSpecific
                ? t('Send {{interval}} emails {{days}}', {
                    interval: sendIntervalFieldProps.value,
                    days: convertToReadableDays(daysToSendFieldProps.value),
                  })
                : t('Send entire email campaign all at once')}
            </Text>
          </>
        )}
      </div>
    ),
  };
};
